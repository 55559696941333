// ** Checks if an object is empty (returns boolean)
import {Fragment} from "react";
import Avatar from "../@core/components/avatar";
import moment from "moment";
import {Slide, toast} from "react-toastify";

export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {month: 'short', day: 'numeric', year: 'numeric'}) => {
    if (!value) return value
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = {month: 'short', day: 'numeric'}

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = {hour: 'numeric', minute: 'numeric'}
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const setUserData = (t) => localStorage.setItem("vitalis_crm_user", t)
export const isUserLoggedIn = () => {
    return localStorage.getItem('vitalis_crm_auth') !== null
}
export const getUserData = () => JSON.parse(localStorage.getItem('vitalis_crm_user'))
export const removeUserData = () => localStorage.removeItem('vitalis_crm_user')

export const setStationCode = (t) => localStorage.setItem("vitalis_crm_station", t)
export const getStationCode = () => localStorage.getItem('vitalis_crm_station')
export const removeStationCode = () => localStorage.removeItem('vitalis_crm_station')

export const setExpirationDate = (t) => localStorage.setItem("vitalis_crm_expiration", t)
export const getExpirationDate = () => localStorage.getItem('vitalis_crm_expiration')
export const removeExpirationDate = () => localStorage.removeItem('vitalis_crm_expiration')

export const setAuthToken = (t) => localStorage.setItem("vitalis_crm_auth", t)
export const getAuthToken = () => localStorage.getItem('vitalis_crm_auth')
export const removeAuthToken = () => localStorage.removeItem("vitalis_crm_auth")
export const geti18Lang = () => localStorage.getItem("i18nextLng")


export const clearCookies = () => {
    removeUserData()
    removeAuthToken()
    removeExpirationDate()
}

export const checkExpirationDate = (t) => {
    const expirationDate = getExpirationDate()
    const expire = moment(new Date(expirationDate))
    const nowDate = moment()

    if (expire < nowDate) {
        clearCookies()
        window.location.href = '/login'
        toast.error(
            <ToastContent header={t("Error")} text={t("Error.expireDate")}/>,
            {transition: Slide, hideProgressBar: true, autoClose: 2000}
        )

    }
}
/**
 ** This function is used for demo purpose route navigation
 ** In real apps you won't need this function because your apps will navigate to same route for each users regardless of ability
 ** Please note roleGroup field is just for showing purpose it's not used by anything in frontend
 ** We are checking roleGroup just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/'
    if (userRole === 'client') return '/access-control'
    return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: 'rgba(255,255,255,0.1)', // for option hover bg-color
        primary: '#3499e2', // for selected option bg-color
        neutral10: '#3499e2', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const ToastContent = ({header, text, icon, color}) => (
    <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                <Avatar size='sm' color={color} icon={icon}/>
                <h6 className='toast-title font-weight-bold'>{header}</h6>
            </div>
        </div>
        <div className='toastify-body'>
            <span>{text}</span>
        </div>
    </Fragment>
)

export const trToEN = (key) => {
    const rep = key.replace(/Ğ/g, "G")
        .replace(/ğ/g, "g")
        .replace(/Ü/g, "U")
        .replace(/ü/g, "u")
        .replace(/Ş/g, "S")
        .replace(/ş/g, "s")
        .replace(/İ/g, "I")
        .replace(/ı/g, "i")
        .replace(/Ö/g, "O")
        .replace(/ö/g, "o")
        .replace(/Ç/g, "C")
        .replace(/ç/g, "c");
    return rep.toLowerCase()
}
